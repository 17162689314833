<template>
  <b-card border-variant="primary">
    <!-- <div>
      <button
        @click="generatePDF(currentTab.element_id, currentTab.pdf_title)"
        :class="'btn btn-primary'"
      >
        Download PDF
      </button>
    </div> -->
    <div v-if="logsExist && (isPrevious || isNext)">
      <b-card-header class="buttons-container d-flex justify-content-center">
        <b-row
          class="border shadow-sm rounded-pill"
          style="width: 300px; padding: 5px"
        >
          <div class="w-25">
            <b-button
              v-if="isPrevious && logsExist"
              :disabled="!isPrevious"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-primary"
              class="bg-primary text-white btn-icon rounded-circle"
              @click="showPreviousLog"
              title="Previous Data"
            >
              <feather-icon icon="ArrowLeftIcon" class="text-white" />
            </b-button>
          </div>
          <div class="d-flex align-items-center justify-content-center w-50">
            <h5>Log {{ currentSelectedIndex + 1 }}</h5>
          </div>
          <div class="w-25 d-flex justify-content-end">
            <b-button
              v-if="isNext && logsExist"
              :disabled="!isNext"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-primary"
              class="text-white bg-primary btn-icon rounded-circle"
              @click="showNextLog"
              title="Next Data"
            >
              <feather-icon icon="ArrowRightIcon" class="text-white" />
            </b-button>
          </div>
        </b-row>
      </b-card-header>
    </div>
    <b-tabs class="d-flex flex-column">
      <b-tab
        title="Contract Details"
        active
        @click="setCurrentTab('contractDetails', 'Contract Details')"
      >
        <b-card id="contractDetails" class="border">
          <b-row v-if="contractInfo.edited_by && contractInfo.updated_at">
            <b-col class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder text-right">
                Edited By {{ contractInfo.edited_by }} on
                {{
                  moment(contractInfo.updated_at).isValid()
                    ? moment(contractInfo.updated_at).format("Do MMMM, YYYY")
                    : "-"
                }}
              </h5>
            </b-col>
          </b-row>
          <hr class="mt-0 mb-2" v-if="contractInfo.edited_by" />

          <!-- Nature of the contract -->

          <b-row>
            <b-col cols="12" sm="6" md="3" class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder">
                Nature Of Contract
              </h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.contract_nature &&
                    previousContractInfo &&
                    contractInfo.contractData.contract_nature !==
                      previousContractInfo.contractData.contract_nature
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.contract_nature
                      ? contractInfo.contractData.contract_nature
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col>

            <b-col cols="12" sm="6" md="3" class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder">Movies</h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    contractInfo.contractData &&
                    previousContractInfo &&
                    contractInfo.contractData.movie_name &&
                    contractInfo.contractData.movie_name !==
                      previousContractInfo.contractData.movie_name
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.movie_name
                      ? contractInfo.contractData.movie_name
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col>

            <b-col cols="12" sm="6" md="3" class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder">Licensor</h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.vendor_company &&
                    previousContractInfo &&
                    contractInfo.contractData.vendor_company !==
                      previousContractInfo.contractData.vendor_company
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.vendor_company
                      ? contractInfo.contractData.vendor_company
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col>
          </b-row>

          <hr class="mt-0 mb-2" />

          <b-row>
            <b-col cols="12" sm="6" md="3" class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder">
                Type Of Contract
              </h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.contract_type_name &&
                    previousContractInfo &&
                    contractInfo.contractData.contract_type_name !==
                      previousContractInfo.contractData.contract_type_name
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.contract_type_name
                      ? contractInfo.contractData.contract_type_name
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col>

            <b-col cols="12" sm="6" md="3" class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder">
                Offer Date
              </h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.offer_date &&
                    previousContractInfo &&
                    contractInfo.contractData.offer_date !==
                      previousContractInfo.contractData.offer_date
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.offer_date
                      ? moment(contractInfo.contractData.offer_date).format(
                          "DD/MM/YYYY"
                        )
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col>

            <b-col cols="12" sm="6" md="3" class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder">
                Contract Date
              </h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    contractInfo.contractData &&
                    previousContractInfo &&
                    contractInfo.contractData.contract_date &&
                    contractInfo.contractData.contract_date !==
                      previousContractInfo.contractData.contract_date
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.contract_date
                      ? moment(contractInfo.contractData.contract_date).format(
                          "DD/MM/YYYY"
                        )
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col>

            <b-col cols="12" sm="6" md="3" class="mb-1">
              <h5 class="text-capitalize mb-0">Comment</h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    previousContractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.contract_comment &&
                    contractInfo.contractData.contract_comment !==
                      previousContractInfo.contractData.contract_comment
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.contract_comment
                      ? contractInfo.contractData.contract_comment
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col>
          </b-row>

          <hr class="mt-0 mb-2" />

          <b-row>
            <b-col cols="12" sm="6" md="3" class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder">
                License Begin
              </h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    previousContractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.contract_begin &&
                    contractInfo.contractData.contract_begin !==
                      previousContractInfo.contractData.contract_begin
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.contract_begin
                      ? moment(contractInfo.contractData.contract_begin).format(
                          "DD/MM/YYYY"
                        )
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col>

            <b-col cols="12" sm="6" md="3" class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder">
                License End
              </h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    previousContractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.contract_end &&
                    contractInfo.contractData.contract_end !==
                      previousContractInfo.contractData.contract_end
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.contract_end
                      ? moment(contractInfo.contractData.contract_end).format(
                          "DD/MM/YYYY"
                        )
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col>

            <b-col cols="12" sm="6" md="3" class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder">Comment</h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    previousContractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.duration_comment &&
                    contractInfo.contractData.duration_comment !==
                      previousContractInfo.contractData.duration_comment
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.duration_comment
                      ? contractInfo.contractData.duration_comment
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col>

            <b-col cols="12" sm="6" md="3" class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder">NOD</h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.nod &&
                    contractInfo.contractData.nod !==
                      previousContractInfo.contractData.nod
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.nod
                      ? moment(contractInfo.contractData.nod).format(
                          "DD/MM/YYYY"
                        )
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col>

            <b-col cols="12" sm="6" md="3" class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder">
                Sell-Off period
              </h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    previousContractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.sellofperiod &&
                    contractInfo.contractData.sellofperiod !==
                      previousContractInfo.contractData.sellofperiod
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.sellofperiod
                      ? contractInfo.contractData.sellofperiod
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col>

            <b-col cols="12" sm="6" md="3" class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder">
                Number of Runs(TV)
              </h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    previousContractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.number_of_run &&
                    contractInfo.contractData.number_of_run !==
                      previousContractInfo.contractData.number_of_run
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.number_of_run
                      ? contractInfo.contractData.number_of_run
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col>

            <b-col cols="12" sm="6" md="3" class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder">
                Material Delivery
              </h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    previousContractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.material_delivery &&
                    contractInfo.contractData.material_delivery !==
                      previousContractInfo.contractData.material_delivery
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.material_delivery
                      ? contractInfo.contractData.material_delivery
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col>
          </b-row>
          <hr class="mt-0 mb-2" />

          <b-row class="mb-2">
            <b-col cols="12" sm="6" md="3" class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder">
                Extension Clause
              </h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    previousContractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.extension_clause &&
                    contractInfo.contractData.extension_clause !=
                      previousContractInfo.contractData.extension_clause
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.extension_clause == 1
                      ? "Yes"
                      : "NO"
                  }}
                </span>
              </b-card-text>
            </b-col>

            <b-col
              cols="12"
              sm="6"
              md="3"
              class="mb-1"
              v-if="contractInfo.contractData.extension_clause == 1"
            >
              <h5 class="text-capitalize mb-0 font-weight-bolder">
                Extension Date
              </h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    previousContractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.extension_date &&
                    contractInfo.contractData.extension_date !==
                      previousContractInfo.contractData.extension_date
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.extension_date
                      ? moment(contractInfo.contractData.extension_date).format(
                          "DD/MM/YYYY"
                        )
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col>

            <b-col
              cols="12"
              sm="6"
              md="3"
              class="mb-1"
              v-if="
                contractInfo &&
                contractInfo.contractData &&
                contractInfo.contractData.extension_clause == 1
              "
            >
              <h5 class="text-capitalize mb-0 font-weight-bolder">
                Extension Comment
              </h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    previousContractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.extension_clause_desc &&
                    contractInfo.contractData.extension_clause_desc !==
                      previousContractInfo.contractData.extension_clause_desc
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.extension_clause_desc
                      ? contractInfo.contractData.extension_clause_desc
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col>
          </b-row>

          <b-row
            class="w-100"
            cols="12"
            v-if="contractInfo.contractData.main_contract_name"
          >
            <b-col cols="12" class="mb-1 w-100">
              <h5 class="text-capitalize mb-0 font-weight-bolder">
                Main Contract Name
              </h5>
              <span>{{ contractInfo.contractData.main_contract_name }}</span>
            </b-col>
          </b-row>

          <hr class="mt-0 mb-2" />

          <!-- Territory And Rights -->
          <div no-body class="mt-1 mb-2">
            <h5 class="text-capitalize mb-2 font-weight-bolder">
              Territory And Rights
            </h5>
            <div style="overflow: hidden; overflow-x: scroll">
              <table class="border rounded-lg">
                <thead class="border">
                  <tr>
                    <th class="border p-1">Territory</th>
                    <th class="border p-1">Rights</th>
                    <th class="border p-1">Languages</th>
                    <th class="border p-1">Subtitle</th>
                    <th class="border p-1">Exclusive</th>
                    <th class="border p-1">Comments</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(trl, trl_index) in contractInfo.trls"
                    :key="'trl' + trl_index"
                  >
                    <td class="border p-1">
                      {{
                        trl.territory
                          ? getTreeNamesById(trl.territory, "territory_tree")
                          : "-"
                      }}
                    </td>
                    <td class="border p-1">
                      {{
                        trl.right
                          ? getTreeNamesById(
                              convertToIntArr(trl.right),
                              "rights_tree"
                            )
                          : "-"
                      }}
                    </td>
                    <td class="border p-1">
                      {{
                        trl.language
                          ? getNamesById(
                              convertToIntArr(trl.language),
                              "language"
                            )
                          : "-"
                      }}
                    </td>
                    <td class="border p-1">
                      {{
                        trl.subtitle
                          ? getNamesById(
                              convertToIntArr(trl.subtitle),
                              "language"
                            )
                          : "-"
                      }}
                    </td>
                    <td class="border p-1">
                      {{ trl.exclusive == 1 ? "Yes" : "No" }}
                    </td>
                    <td class="border p-1">{{ trl.comment }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <hr class="mt-0 mb-2" />

          <b-row>
            <b-col cols="12" sm="6" md="3" class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder">
                Revenue Share Type
              </h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    previousContractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.revenue_share_type &&
                    contractInfo.contractData.revenue_share_type !==
                      previousContractInfo.contractData.revenue_share_type
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.revenue_share_type
                      ? getNamesById(
                          [contractInfo.contractData.revenue_share_type],
                          "revenue_share"
                        )
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col>

            <b-col cols="12" sm="6" md="3" class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder">MG</h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    previousContractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.mgadvance &&
                    contractInfo.contractData.mgadvance !==
                      previousContractInfo.contractData.mgadvance
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.mgadvance
                      ? contractInfo.contractData.mgadvance
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col>

            <b-col cols="12" sm="6" md="3" class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder">
                MG Currency
              </h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    previousContractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.c_currency &&
                    contractInfo.contractData.c_currency !==
                      previousContractInfo.contractData.c_currency
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.c_currency
                      ? getNamesById(
                          [contractInfo.contractData.c_currency],
                          "currency"
                        )
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col>

            <b-col cols="12" sm="6" md="3" class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder">Comment</h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    previousContractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.r_comment &&
                    contractInfo.contractData.r_comment !==
                      previousContractInfo.contractData.r_comment
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.r_comment
                      ? contractInfo.contractData.r_comment
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col>
          </b-row>

          <hr class="mt-0 mb-2" />

          <!-- Milestones -->
          <div no-body class="mt-1 mb-2">
            <h5 class="text-capitalize mb-2 font-weight-bolder">Milestones</h5>
            <div style="overflow-x: auto">
              <table class="border rounded-lg">
                <thead class="border">
                  <tr>
                    <th class="border p-1">Title</th>
                    <th class="border p-1">Payable (%)</th>
                    <th class="border p-1">Amount</th>
                    <th class="border p-1">Due Date</th>
                    <th class="border p-1">Comments</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      milestoneData, milestoneData_index
                    ) in contractInfo.milestonesData"
                    :key="'milestoneData' + milestoneData_index"
                  >
                    <td class="border p-1">
                      {{
                        getNamesById(
                          [milestoneData.milestone_name],
                          "milestone"
                        ) || "-"
                      }}
                    </td>
                    <td class="border p-1">
                      {{ milestoneData.payable }}
                    </td>
                    <td class="border p-1">
                      {{ milestoneData.amountpayable }}
                    </td>
                    <td class="border p-1">
                      {{
                        milestoneData.duedate
                          ? moment(milestoneData.duedate).format("DD/MM/YYYY")
                          : "-"
                      }}
                    </td>
                    <td class="border p-1">{{ milestoneData.comment }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <hr class="mt-0 mb-2" />

          <b-row>
            <!-- check later -->
            <!-- <b-col cols="12" sm="6" md="3" class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder">P&A Cap</h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.pacap &&
                    contractInfo.contractData.pacap !==
                      contractLogs[currentSelectedIndex - 1].contractData.pacap
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.pacap
                      ? contractInfo.contractData.pacap
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col> -->

            <b-col cols="12" sm="6" md="3" class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder">
                P&A Internal
              </h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    previousContractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.painternal &&
                    contractInfo.contractData.painternal !==
                      previousContractInfo.contractData.painternal
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.painternal
                      ? contractInfo.contractData.painternal
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col>

            <b-col cols="12" sm="6" md="3" class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder">
                P&A Budget Approved
              </h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    previousContractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.pabapproved &&
                    contractInfo.contractData.pabapproved !==
                      previousContractInfo.contractData.pabapproved
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.pabapproved
                      ? contractInfo.contractData.pabapproved
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col>

            <b-col cols="12" sm="6" md="3" class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder">
                P&A Distribution Support
              </h5>
              <b-card-text>
                <span
                  class="text-dark"
                  :class="
                    currentSelectedIndex &&
                    contractInfo &&
                    previousContractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.padistribution &&
                    contractInfo.contractData.padistribution !==
                      previousContractInfo.contractData.padistribution
                      ? 'bg-gradient-warning'
                      : ''
                  "
                >
                  {{
                    contractInfo &&
                    contractInfo.contractData &&
                    contractInfo.contractData.padistribution
                      ? contractInfo.contractData.padistribution
                      : "-"
                  }}
                </span>
              </b-card-text>
            </b-col>
          </b-row>

          <hr class="mt-0 mb-2" />

          <!-- Bumpers -->
          <div no-body class="mt-1 mb-2">
            <h5 class="text-capitalize mb-2 font-weight-bolder">Bumpers</h5>
            <div style="overflow-x: auto">
              <table class="border rounded-lg">
                <thead class="border">
                  <tr>
                    <th class="border p-1">Bumper</th>
                    <th class="border p-1">Amount</th>
                    <th class="border p-1">Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(bumper, bumper_index) in contractInfo.bumpers"
                    :key="'bumper' + bumper_index"
                  >
                    <td class="border p-1">
                      {{ bumper.bumper }}
                    </td>
                    <td class="border p-1">
                      {{ bumper.amount }}
                    </td>
                    <td class="border p-1">
                      {{
                        bumper.date
                          ? moment(bumper.date).format("DD/MM/YYYY")
                          : "-"
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <hr class="mt-0 mb-2" />

          <!-- Statement Period -->
          <div class="mb-2 mt-1">
            <h5 class="text-capitalize mb-2 font-weight-bolder">
              Statement Period
            </h5>
            <div style="overflow-x: auto">
              <table class="border rounded-lg">
                <thead class="border">
                  <tr>
                    <th class="border p-1">Statement Period</th>
                    <th class="border p-1">Duration statements (months)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      statementPeriod, statementPeriod_index
                    ) in contractInfo.contractStatements"
                    :key="'statementPeriod' + statementPeriod_index"
                  >
                    <td class="border p-1">
                      {{ statementPeriod.period }}
                    </td>
                    <td class="border p-1">
                      {{ statementPeriod.duration }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <hr class="mt-0 mb-2" />

          <div class="mb-2 mt-1">
            <h5 class="text-capitalize mb-2 font-weight-bolder">
              Broadcast Dates
            </h5>
            <div style="overflow-x: auto">
              <table class="border rounded-lg">
                <thead class="border">
                  <tr>
                    <th class="border p-1">Date</th>
                    <th class="border p-1">Figures</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      broadcast, broadcast_index
                    ) in contractInfo.broadcasts"
                    :key="'broadcast' + broadcast_index"
                  >
                    <td class="border p-1">
                      {{
                        broadcast.date
                          ? moment(broadcast.date).format("DD/MM/YYYY")
                          : "-"
                      }}
                    </td>
                    <td class="border p-1">
                      {{ broadcast.figures }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <hr class="mt-0 mb-2" />

          <!-- Language Access -->
          <div class="mt-1 mb-2">
            <h5 class="text-capitalize mb-2 font-weight-bolder">
              Language Access
            </h5>
            <div style="overflow-x: auto">
              <table class="border rounded-lg">
                <thead class="border">
                  <tr>
                    <th class="border p-1">Language</th>
                    <th class="border p-1">Access</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      languageAccess, languageAccess_index
                    ) in contractInfo.languages"
                    :key="'languageAccess' + languageAccess_index"
                  >
                    <td class="border p-1">
                      {{ languageAccess.language }}
                    </td>
                    <td class="border p-1">
                      {{ languageAccess.access }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <hr class="mt-0 mb-2" />

          <b-row>
            <b-col cols="12" class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder">
                Purchase of finished products from licensor
              </h5>
              <b-card-text>
                {{ contractInfo.contractProducts.length ? "YES" : "NO" }}
              </b-card-text>
            </b-col>
          </b-row>

          <hr class="mt-0 mb-2" />

          <!-- Finished Product Recheck -->
          <div class="mt-1 mb-2">
            <h5 class="text-capitalize mb-2 font-weight-bolder">
              Finished Product
            </h5>
            <div style="overflow-x: auto">
              <table class="border rounded-lg">
                <thead class="border">
                  <tr>
                    <th class="border p-1">Product</th>
                    <th class="border p-1">Price</th>
                    <th class="border p-1">Currency</th>
                    <th class="border p-1">Free Samples for licensor</th>
                    <th class="border p-1">Flat price for Licensor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      contractProduct, contractProduct_index
                    ) in contractInfo.contractProducts"
                    :key="'contractProduct' + contractProduct_index"
                  >
                    <td class="border p-1">
                      {{ contractProduct.product }}
                    </td>
                    <td class="border p-1">
                      {{ contractProduct.price }}
                    </td>
                    <td class="border p-1">
                      {{ contractProduct.currency }}
                    </td>
                    <td class="border p-1">
                      {{ contractProduct.flicensor }}
                    </td>
                    <td class="border p-1">
                      {{ contractProduct.licensor }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <hr class="mt-0 mb-2" />

          <!-- Holdback Recheck-->
          <div class="mt-1 mb-2">
            <h5 class="text-capitalize mb-2 font-weight-bolder">Holdback</h5>
            <div style="overflow-x: auto">
              <table class="border rounded-lg">
                <thead class="border">
                  <tr>
                    <th class="border p-1">Rights</th>
                    <th class="border p-1">Comment</th>
                    <th class="border p-1">Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(holdback, holdback_index) in contractInfo.holdback"
                    :key="'holdback' + holdback_index"
                  >
                    <td class="border p-1">
                      {{ holdback.right }}
                    </td>
                    <td class="border p-1">
                      {{ holdback.comment }}
                    </td>
                    <td class="border p-1">
                      {{
                        holdback.date
                          ? moment(holdback.date).format("DD/MM/YYYY")
                          : "-"
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <hr class="mt-0 mb-2" />

          <!-- Support -->
          <div class="mt-1 mb-2">
            <h5 class="text-capitalize mb-2 font-weight-bolder">Support</h5>
            <div style="overflow: hidden; overflow-x: scroll">
              <table class="border rounded-lg">
                <thead class="border">
                  <tr>
                    <th class="border p-1">Status</th>
                    <th class="border p-1">Title</th>
                    <th class="border p-1">Deadline Date</th>
                    <th class="border p-1">Deadline Date1</th>
                    <th class="border p-1">Uploaded Document</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      supportData, supportData_index
                    ) in contractInfo.supportData"
                    :key="'supportData' + supportData_index"
                  >
                    <td class="border p-1">
                      {{ supportData.status1 == 1 ? "Active" : "Inactive" }}
                    </td>
                    <td class="border p-1">
                      {{ supportData.title }}
                    </td>
                    <td class="border p-1">
                      {{
                        supportData.date1
                          ? moment(supportData.date1).format("DD/MM/YYYY")
                          : "-"
                      }}
                    </td>
                    <td class="border p-1">
                      {{
                        supportData.date1
                          ? moment(supportData.date1).format("DD/MM/YYYY")
                          : "-"
                      }}
                    </td>
                    <td class="border p-1">
                      <a
                        v-if="supportData.FILE"
                        :href="baseUrl + supportData.FILE"
                        target="_blank"
                        rel="noopener noreferrer"
                        >File</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <hr class="mt-0 mb-2" />

          <div class="mt-1 mb-2">
            <h5 class="text-capitalize mb-2 font-weight-bolder">
              Revenue Share
            </h5>
            <div style="overflow-x: auto">
              <table class="border rounded-lg">
                <thead class="border">
                  <tr>
                    <th class="border p-1">SR</th>
                    <th class="border p-1">Right</th>
                    <th class="border p-1">Language</th>
                    <th class="border p-1">Territory</th>
                    <th class="border p-1">Licensor Share</th>
                    <th class="border p-1">Exclusive</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      contractApproval, contractApproval_index
                    ) in contractInfo.contractApproval"
                    :key="'contractApproval' + contractApproval_index"
                  >
                    <td class="border p-1">
                      {{ contractApproval_index + 1 }}
                    </td>
                    <td class="border p-1">
                      {{
                        contractApproval.right_id
                          ? getTreeNamesById(
                              [contractApproval.right_id],
                              "rights_tree"
                            )
                          : "-"
                      }}
                    </td>
                    <td class="border p-1">
                      {{
                        getNamesById(
                          [contractApproval.language_id],
                          "language"
                        ) || "-"
                      }}
                    </td>
                    <td class="border p-1">
                      {{
                        getTreeNamesById(
                          [contractApproval.territory_id],
                          "territory_tree"
                        )
                      }}
                    </td>
                    <td class="border p-1">
                      <div
                        v-if="contractApproval.right_id == 2"
                        class=""
                        style="font-size: 8px"
                      >
                        <p class="font-weight-bolder" style="font-size: 14px">
                          {{ contractApproval.licensor_share }}
                        </p>
                        <b-row>
                          <b-col sm="6">
                            <span class="underline">
                              DEDUCTION P&A FROM CINEMATIC GROSS RECEIPTS (Cost
                              off Top Deal)</span
                            >
                            <span
                              class="ml-2 font-weight-bold"
                              :class="
                                contractApproval.deduction_from_rental_pa == 1
                                  ? 'text-success'
                                  : 'text-danger'
                              "
                            >
                              -&nbsp;
                              {{
                                contractApproval.deduction_from_rental_pa == 1
                                  ? "Yes"
                                  : "No"
                              }}
                            </span>
                          </b-col>
                          <b-col sm="6">
                            <span class="underline"
                              >DEDUCTION MG FROM CINEMATIC GROSS RECEIPTS</span
                            >
                            <span
                              class="ml-2 font-weight-bold"
                              :class="
                                contractApproval.deduction_from_rental_mg == 1
                                  ? 'text-success'
                                  : 'text-danger'
                              "
                            >
                              -&nbsp;
                              {{
                                contractApproval.deduction_from_rental_mg == 1
                                  ? "Yes"
                                  : "No"
                              }}
                            </span>
                          </b-col>

                          <b-col sm="6">
                            <span class="underline">
                              DISTRIBUTOR CORRIDOR PRIOR TO RECOUPEMENT OF P&A
                            </span>
                            <span
                              class="ml-2 font-weight-bold"
                              :class="
                                contractApproval.distributor_share_status == 1
                                  ? 'text-success'
                                  : 'text-danger'
                              "
                            >
                              -&nbsp;
                              {{
                                contractApproval.distributor_share_status == 1
                                  ? "Yes"
                                  : "No"
                              }}
                            </span>
                          </b-col>
                          <b-col sm="6">
                            {{
                              contractApproval.distributor_share_status == 1
                                ? contractApproval.distributor_share
                                : "0"
                            }}
                          </b-col>

                          <b-col sm="6">
                            <span class="underline">
                              DISTRIBUTOR CORRIDOR PRIOR TO RECOUPEMENT OF MG
                            </span>
                            <span
                              class="ml-2 font-weight-bold"
                              :class="
                                contractApproval.distributor_share_mg_status ==
                                1
                                  ? 'text-success'
                                  : 'text-danger'
                              "
                            >
                              -&nbsp;
                              {{
                                contractApproval.distributor_share_mg_status ==
                                1
                                  ? "Yes"
                                  : "No"
                              }}
                            </span>
                          </b-col>
                          <b-col sm="6">
                            {{
                              contractApproval.distributor_share_mg_status == 1
                                ? contractApproval.distributor_share_mg_percent
                                : "0"
                            }}
                          </b-col>

                          <b-col sm="6">
                            <span class="underline">
                              DISTRIBUTOR CORRIDOR PRIOR TO RECOUPEMENT OF P&A
                              and MG
                            </span>
                            <span
                              class="ml-2 font-weight-bold"
                              :class="
                                contractApproval.distributor_commision_rental_status ==
                                1
                                  ? 'text-success'
                                  : 'text-danger'
                              "
                            >
                              -&nbsp;
                              {{
                                contractApproval.distributor_commision_rental_status ==
                                1
                                  ? "Yes"
                                  : "No"
                              }}
                            </span>
                          </b-col>
                          <b-col sm="6">
                            {{
                              contractApproval.distributor_commision_rental_status ==
                              1
                                ? contractApproval.distributor_commision_rental_percent
                                : "0"
                            }}
                          </b-col>

                          <b-col sm="6">
                            <span class="underline">
                              LICENSOR CORRIDOR PRIOR TO RECOUPEMENT OF P&A
                            </span>
                            <span
                              class="ml-2 font-weight-bold"
                              :class="
                                contractApproval.distributor_share_status == 1
                                  ? 'text-success'
                                  : 'text-danger'
                              "
                            >
                              -&nbsp;
                              {{
                                contractApproval.distributor_share_status == 1
                                  ? "Yes"
                                  : "No"
                              }}
                            </span>
                          </b-col>
                          <b-col sm="6">
                            {{
                              contractApproval.distributor_share_status == 1
                                ? contractApproval.distributor_share
                                : "0"
                            }}
                          </b-col>

                          <b-col sm="6">
                            <span class="underline">
                              LICENSOR CORRIDOR PRIOR TO RECOUPEMENT OF MG
                            </span>
                            <span
                              class="ml-2 font-weight-bold"
                              :class="
                                contractApproval.distributor_share_mg_status ==
                                1
                                  ? 'text-success'
                                  : 'text-danger'
                              "
                            >
                              -&nbsp;
                              {{
                                contractApproval.distributor_share_mg_status ==
                                1
                                  ? "Yes"
                                  : "No"
                              }}
                            </span>
                          </b-col>
                          <b-col sm="6">
                            {{
                              contractApproval.distributor_share_mg_status == 1
                                ? contractApproval.distributor_share_mg_percent
                                : "0"
                            }}
                          </b-col>
                        </b-row>
                      </div>
                      <div v-else>{{ contractApproval.licensor_share }}</div>
                    </td>
                    <td class="border p-1">
                      {{ contractApproval.exclusive == 1 ? "Yes" : "No" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-card>
      </b-tab>
      <b-tab
        title="Revenue Shares"
        @click="setCurrentTab('revenueShareDetails', 'Revenue Share Details')"
      >
        <b-card class="border" id="revenueShareDetails">
          <!-- <template #header>
            <b-row align-h="between" class="w-100">
              <b-col>
                <b-button
                  v-if="isPrevious && logsExist"
                  :disabled="!isPrevious"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="flat-primary"
                  class="btn-icon rounded-circle"
                  @click="showPreviousLog"
                  title="Previous Data"
                >
                  <feather-icon icon="ArrowLeftIcon" />
                </b-button>
              </b-col>
              <b-col class="text-right">
                <b-button
                  v-if="isNext && logsExist"
                  :disabled="!isNext"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="flat-primary"
                  class="btn-icon rounded-circle"
                  @click="showNextLog"
                  title="Next Data"
                >
                  <feather-icon icon="ArrowRightIcon" />
                </b-button>
              </b-col>
            </b-row>
          </template> -->

          <!-- Last Edited -->

          <b-row v-if="contractInfo.edited_by && contractInfo.updated_at">
            <b-col class="mb-1">
              <h5 class="text-capitalize mb-0 font-weight-bolder text-right">
                Edited By {{ contractInfo.edited_by }} on
                {{
                  moment(contractInfo.updated_at).isValid()
                    ? moment(contractInfo.updated_at).format("Do MMMM, YYYY")
                    : "-"
                }}
              </h5>
            </b-col>
          </b-row>
          <hr class="mt-0 mb-2" v-if="contractInfo.edited_by" />

          <!-- Revenue Share -->
          <b-card no-body class="border mt-1">
            <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon icon="LockIcon" size="18" />
                <span class="align-middle ml-50">Revenue Share</span>
              </b-card-title>
            </b-card-header>
            <b-table
              small
              striped
              responsive
              class="mb-0"
              :fields="revenueShareFields"
              :items="contractInfo.contractApproval"
              primary-key="id"
              show-empty
              empty-text="No matching records found"
            >
              <template #cell(sr)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(right_id)="data">
                <div>
                  <span class="font-weight-bold">
                    {{
                      data.item.right_id
                        ? getTreeNamesById([data.item.right_id], "rights_tree")
                        : "-"
                    }}
                  </span>
                  <!-- &nbsp;{{
                data.item.parent_right_id
                  ? `(${data.item.parent_right_name})`
                  : null
              }} -->
                </div>
              </template>

              <template #cell(language_id)="data">
                <div>
                  <span class="font-weight-bold">{{
                    getNamesById([data.item.language_id], "language") || "-"
                  }}</span>
                </div>
              </template>

              <template #cell(territory_id)="data">
                <div>
                  <span class="font-weight-bold">
                    {{
                      getTreeNamesById(
                        [data.item.territory_id],
                        "territory_tree"
                      )
                    }}
                  </span>
                </div>
              </template>

              <template #cell(licensor_share)="data">
                <div
                  v-if="data.item.right_id == 2"
                  class=""
                  style="font-size: 8px"
                >
                  <p class="font-weight-bolder" style="font-size: 14px">
                    {{ data.item.licensor_share }}
                  </p>
                  <b-row>
                    <b-col sm="6">
                      <span class="underline">
                        DEDUCTION P&A FROM CINEMATIC GROSS RECEIPTS (Cost off
                        Top Deal)</span
                      >
                      <span
                        class="ml-2 font-weight-bold"
                        :class="
                          data.item.deduction_from_rental_pa == 1
                            ? 'text-success'
                            : 'text-danger'
                        "
                      >
                        -&nbsp;
                        {{
                          data.item.deduction_from_rental_pa == 1 ? "Yes" : "No"
                        }}
                      </span>
                    </b-col>
                    <b-col sm="6">
                      <span class="underline"
                        >DEDUCTION MG FROM CINEMATIC GROSS RECEIPTS</span
                      >
                      <span
                        class="ml-2 font-weight-bold"
                        :class="
                          data.item.deduction_from_rental_mg == 1
                            ? 'text-success'
                            : 'text-danger'
                        "
                      >
                        -&nbsp;
                        {{
                          data.item.deduction_from_rental_mg == 1 ? "Yes" : "No"
                        }}
                      </span>
                    </b-col>

                    <b-col sm="6">
                      <span class="underline">
                        DISTRIBUTOR CORRIDOR PRIOR TO RECOUPEMENT OF P&A
                      </span>
                      <span
                        class="ml-2 font-weight-bold"
                        :class="
                          data.item.distributor_share_status == 1
                            ? 'text-success'
                            : 'text-danger'
                        "
                      >
                        -&nbsp;
                        {{
                          data.item.distributor_share_status == 1 ? "Yes" : "No"
                        }}
                      </span>
                    </b-col>
                    <b-col sm="6">
                      {{
                        data.item.distributor_share_status == 1
                          ? data.item.distributor_share
                          : "0"
                      }}
                    </b-col>

                    <b-col sm="6">
                      <span class="underline">
                        DISTRIBUTOR CORRIDOR PRIOR TO RECOUPEMENT OF MG
                      </span>
                      <span
                        class="ml-2 font-weight-bold"
                        :class="
                          data.item.distributor_share_mg_status == 1
                            ? 'text-success'
                            : 'text-danger'
                        "
                      >
                        -&nbsp;
                        {{
                          data.item.distributor_share_mg_status == 1
                            ? "Yes"
                            : "No"
                        }}
                      </span>
                    </b-col>
                    <b-col sm="6">
                      {{
                        data.item.distributor_share_mg_status == 1
                          ? data.item.distributor_share_mg_percent
                          : "0"
                      }}
                    </b-col>

                    <b-col sm="6">
                      <span class="underline">
                        DISTRIBUTOR CORRIDOR PRIOR TO RECOUPEMENT OF P&A and MG
                      </span>
                      <span
                        class="ml-2 font-weight-bold"
                        :class="
                          data.item.distributor_commision_rental_status == 1
                            ? 'text-success'
                            : 'text-danger'
                        "
                      >
                        -&nbsp;
                        {{
                          data.item.distributor_commision_rental_status == 1
                            ? "Yes"
                            : "No"
                        }}
                      </span>
                    </b-col>
                    <b-col sm="6">
                      {{
                        data.item.distributor_commision_rental_status == 1
                          ? data.item.distributor_commision_rental_percent
                          : "0"
                      }}
                    </b-col>

                    <b-col sm="6">
                      <span class="underline">
                        LICENSOR CORRIDOR PRIOR TO RECOUPEMENT OF P&A
                      </span>
                      <span
                        class="ml-2 font-weight-bold"
                        :class="
                          data.item.distributor_share_status == 1
                            ? 'text-success'
                            : 'text-danger'
                        "
                      >
                        -&nbsp;
                        {{
                          data.item.distributor_share_status == 1 ? "Yes" : "No"
                        }}
                      </span>
                    </b-col>
                    <b-col sm="6">
                      {{
                        data.item.distributor_share_status == 1
                          ? data.item.distributor_share
                          : "0"
                      }}
                    </b-col>

                    <b-col sm="6">
                      <span class="underline">
                        LICENSOR CORRIDOR PRIOR TO RECOUPEMENT OF MG
                      </span>
                      <span
                        class="ml-2 font-weight-bold"
                        :class="
                          data.item.distributor_share_mg_status == 1
                            ? 'text-success'
                            : 'text-danger'
                        "
                      >
                        -&nbsp;
                        {{
                          data.item.distributor_share_mg_status == 1
                            ? "Yes"
                            : "No"
                        }}
                      </span>
                    </b-col>
                    <b-col sm="6">
                      {{
                        data.item.distributor_share_mg_status == 1
                          ? data.item.distributor_share_mg_percent
                          : "0"
                      }}
                    </b-col>
                  </b-row>
                </div>
                <div v-else>
                  {{ data.item.licensor_share }}
                </div>
              </template>
              <template #cell(exclusive)="data">
                {{ data.item.exclusive == 1 ? "Yes" : "No" }}
              </template>
            </b-table>
          </b-card>
        </b-card>
      </b-tab>
    </b-tabs>
  </b-card>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BFormDatepicker,
  BFormTextarea,
  BTable,
  BCardHeader,
  BCardTitle,
  BCardText,
  BTab,
  BTabs,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Treeselect from "@riophae/vue-treeselect";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import {
  GetContractLogs,
  getSingleContract,
} from "@/apiServices/ContractServices";
import { labels } from "@/utils/constants";
import { getNamesById, generatePDF } from "@/utils/helpers";
import { getVendorByID } from "@/apiServices/VendorServices";

import moment from "moment";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BFormTextarea,
    AppCollapse,
    AppCollapseItem,
    BFormDatepicker,
    vSelect,
    Treeselect,
    flatPickr,
    FeatherIcon,
    BTable,
    BCardHeader,
    BCardTitle,
    BCardText,
    BTab,
    BTabs,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment: moment,
      contract_id: null,
      currentTab: {
        element_id: "contractDetails",
        pdf_title: "Contract Details",
      },
      logsExist: false,
      currentSelectedIndex: 0,
      previousSelectedIndex: null,
      result: null,
      previousContractInfo: {
        bumpers: [],
        contractApproval: [],
        contractData: {},
        contractProducts: [],
        contractStatements: [],
        holdback: [],
        langauges: [],
        milestonesData: [],
        supportData: [],
        trls: [],
      },
      contractInfo: {
        bumpers: [],
        contractApproval: [],
        contractData: {},
        contractProducts: [],
        contractStatements: [],
        holdback: [],
        langauges: [],
        milestonesData: [],
        supportData: [],
        trls: [],
        edited_by: null,
        updated_at: null,
      },
      contractData: null,
      previousContractInfo: null,
      contractLogs: [],
      TRLSFields: [
        {
          key: "territory",
          label: "Territory",
          sortable: true,
          formatter: (value) => value || "-",
        },
        {
          key: "right",
          label: "Rights",
          sortable: true,
          formatter: (value) => value || "-",
        },
        {
          key: "language",
          label: "Languages",
          sortable: true,
          formatter: (value) => value || "-",
        },
        {
          key: "subtitle",
          label: "Subtitle",
          sortable: true,
          formatter: (value) => value || "-",
        },
        {
          key: "exclusive",
          label: "Exclusive",
          sortable: true,
          formatter: (value) => value || "-",
        },
        {
          key: "comment",
          label: "Comment",
          sortable: true,
          formatter: (value) => value || "-",
        },
      ],
      milestoneFields: [
        {
          key: "milestone_name",
          label: "Title",
          sortable: true,
          formatter: (value) => value || "-",
        },
        {
          key: "payable",
          label: "Payable (%)",
          sortable: true,
          formatter: (value) => value || "-",
        },
        {
          key: "amountpayable",
          label: "Amount",
          sortable: true,
          formatter: (value) => value || "-",
        },
        {
          key: "duedate",
          label: "Due Date",
          sortable: true,
          formatter: (value) => value || "-",
        },
        {
          key: "comment",
          label: "Comment",
          sortable: true,
          formatter: (value) => value || "-",
        },
      ],
      bumperFields: [
        {
          key: "bumper",
          label: "Bumper",
          sortable: true,
          formatter: (value) => value || "-",
        },
        {
          key: "amount",
          label: "Amount",
          sortable: true,
          formatter: (value) => value || "-",
        },
        {
          key: "DATE",
          label: "Date",
          sortable: true,
          formatter: (value) => value || "-",
        },
      ],
      statementPeriodFields: [
        {
          key: "period",
          label: "Statement Period",
          formatter: (value) => value || "-",
        },
        {
          key: "duration",
          label: "Duration Statements (Months)",
          formatter: (value) => value || "-",
        },
      ],
      broadcastFields: [
        {
          key: "date",
          label: "Date",
          formatter: (value) =>
            value ? moment(value).format("DD-MMM-YYYY") : "-",
        },

        {
          key: "figures",
          label: "Figures",
          formatter: (value) => value || "-",
        },
      ],
      languageAccessFields: [
        {
          key: "language",
          label: "Language",
          formatter: (value) => value || "-",
        },
        {
          key: "access",
          label: "Access",
          formatter: (value) => value || "-",
        },
      ],
      finishedProductFields: [
        {
          key: "product",
          label: "Product",
          formatter: (value) => value || "-",
        },
        {
          key: "price",
          label: "Price",
          formatter: (value) => value || "-",
        },
        {
          key: "currency",
          label: "Currency",
          formatter: (value) => value || "-",
        },
        {
          key: "flicensor",
          label: "Free Samples for licensor",
          formatter: (value) => value || "-",
        },
        {
          key: "licensor",
          label: "Flat price for Licensor",
          formatter: (value) => value || "-",
        },
      ],
      holdbackFields: [
        // {
        //   key: "title_name",
        //   label: "Title",
        // },
        {
          key: "right_names",
          label: "Rights",
          formatter: (value) => value || "-",
        },
        {
          key: "comment",
          label: "Comment",
          formatter: (value) => value || "-",
        },
        {
          key: "date",
          label: "Date",
          formatter: (value) => value || "-",
        },
      ],
      supportFields: [
        {
          key: "status1",
          label: "Status",
          formatter: (value) => value || "-",
        },
        {
          key: "title",
          label: "Title",
          formatter: (value) => value || "-",
        },
        {
          key: "date1",
          label: "Deadline Date",
          formatter: (value) => value || "-",
        },
        {
          key: "date2",
          label: "Deadline Date1",
          formatter: (value) => value || "-",
        },
        {
          key: "FILE",
          label: "Uploaded Document",
          formatter: (value) => value || "-",
        },
      ],
      revenueShareFields: [
        {
          label: "SR",
          key: "sr",
          formatter: (value) => value || "-",
        },
        {
          label: "Right",
          key: "right_id",
          formatter: (value) => value || "-",
        },
        {
          label: "Language",
          key: "language_id",
          formatter: (value) => value || "-",
        },
        {
          label: "Territory",
          key: "territory_id",
          formatter: (value) => value || "-",
        },
        {
          label: "Licensor Share",
          key: "licensor_share",
          formatter: (value) => value || "-",
        },
        {
          label: "Exclusive",
          key: "exclusive",
          formatter: (value) => value || "-",
        },
      ],
    };
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_FILESURL;
    },
    isPrevious() {
      return this.currentSelectedIndex == 0 ? false : true;
    },
    isNext() {
      return this.currentSelectedIndex == this.result?.length - 1
        ? false
        : true;
    },
    masterData() {
      return this.$store.state.master;
    },
  },
  methods: {
    async fetchContracts() {
      let response = await GetContractLogs(this.contract_id);

      if (response.data.data[0]) {
        this.logsExist = true;
        this.result = response.data.data;
        this.currentSelectedIndex = this.result.length - 1;
      } else {
        response = await getSingleContract(this.contract_id);
        this.result = response.data.data;
      }

      this.getContracts();
    },

    async getContracts() {
      if (Array.isArray(this.result)) {
        this.contractLogs = this.result;

        this.contractData = JSON.parse(
          this.contractLogs[this.currentSelectedIndex].json_text
        );

        if (this.contractLogs[this.currentSelectedIndex - 1]) {
          this.previousData = JSON.parse(
            this.contractLogs[this.currentSelectedIndex - 1].json_text
          );
        }

        if (this.previousData) {
          this.previousContractInfo = {
            trls: this.previousData.trls,
            contractData: this.previousData.contract,
            milestonesData: this.previousData.milestones,
            bumpers: this.previousData.bumpers,
            contractStatements: this.contractData.statement,
            languages: this.previousData.access,
            broadcasts: this.previousData.broadcast,
            contractProducts: this.previousData.products,
            holdback: this.previousData.hold_back,
            supportData: this.previousData.support,
            contractApproval: this.previousData.contract_approval,
          };
        }
      } else {
        this.contractData = this.result;
      }

      this.contractInfo = {
        trls: this.contractData.trls,
        contractData: this.contractData.contract,
        milestonesData: this.contractData.milestones,
        bumpers: this.contractData.bumpers,
        contractStatements: this.contractData.statement,
        languages: this.contractData.access,
        broadcasts: this.contractData.broadcast,
        contractProducts: this.contractData.products,
        holdback: this.contractData.hold_back,
        supportData: this.contractData.support,
        contractApproval: this.contractData.contract_approval,
        edited_by: this.contractLogs[this.currentSelectedIndex]
          ? this.contractLogs[this.currentSelectedIndex].name
          : null,
        updated_at: this.contractLogs[this.currentSelectedIndex]
          ? this.contractLogs[this.currentSelectedIndex].updatedAt
          : null,
      };

      console.log({ contractInfo: this.contractInfo });
    },

    showPreviousLog() {
      if (this.isPrevious) {
        this.currentSelectedIndex--;
        this.getContracts();
      } else {
        this.isPrevious = false;
      }
    },
    showNextLog() {
      if (this.isNext) {
        this.currentSelectedIndex++;
        this.getContracts();
      } else {
        this.isNext = true;
      }
    },
    generatePDF,
    // getNamesById(id_array, item_name) {
    //   if (!id_array) return;
    //   let item_objects = this.masterData[item_name].filter((item) =>
    //     id_array.includes(item.lbl_id)
    //   );
    //   let item_names = item_objects.map((item) => item.lbl_name).join(", ");
    //   return item_names;
    // },
    convertToIntArr(str) {
      if (str && typeof str === "string") {
        const arr = str.split(",");
        if (arr.length) {
          return arr.map((i) => parseInt(i));
        }

        return [];
      }
      return [];
    },

    getTreeObjects(key) {
      let final_array = [];
      for (let object of this.masterData[key]) {
        final_array = [...final_array, object];

        if (object.children) {
          for (let child of object.children) {
            final_array = [...final_array, child];
          }
        }
      }
      return final_array;
    },

    getTreeNamesById(territory_id_array, key) {
      const AllTerritories = this.getTreeObjects(key);

      if (!territory_id_array) return;
      let currentTerritories = AllTerritories.filter((territory) =>
        territory_id_array.includes(territory.id)
      );
      let territory_names = currentTerritories
        .map((territory) => territory.label)
        .join(", ");
      return territory_names;
    },

    getNamesById,

    setCurrentTab(element_id, pdf_title) {
      this.currentTab = {
        element_id,
        pdf_title,
      };
    },
  },

  beforeMount() {
    this.contract_id = this.$route.params.contract_id;
    this.$store.dispatch("master/setLabelMaster", { key: labels.territory });
    this.$store.dispatch("master/setLabelTreeMaster", {
      key: labels.territory_tree,
      master: labels.territory,
      sub_master: labels.sub_territory,
    });
    this.$store.dispatch("master/setLabelMaster", { key: labels.rights });
    this.$store.dispatch("master/setLabelTreeMaster", {
      key: labels.rights_tree,
      master: labels.rights,
      sub_master: labels.sub_right,
    });
    this.$store.dispatch("master/setLabelMaster", { key: labels.language });
    this.$store.dispatch("master/setLabelMaster", { key: labels.bumper });
    this.$store.dispatch("master/setLabelMaster", {
      key: labels.revenue_share,
    });
    this.$store.dispatch("master/setLabelMaster", {
      key: labels.currency,
    });
    this.$store.dispatch("master/setLabelMaster", { key: labels.bumper });
    this.$store.dispatch("master/setLabelMaster", { key: labels.milestone });
    this.fetchContracts();
  },
};
</script>
<style scoped>
.grid-column {
  display: grid;
  grid-template-columns: 29% 69%;
  grid-gap: 2%;
  height: 100%;
}
.card-bg-white {
  background-color: white;
}
.card-bg-gray {
  background-color: rgb(235, 235, 235);
}
.text-color {
  color: #1f307a;
}
.v-slide-group__container {
  contain: content;
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
}
.underline {
  text-decoration: underline !important;
}

.buttons-container {
  padding: 10px !important;
}
</style>
